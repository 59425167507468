.App {
  min-height: 100vh;
  text-align: center;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
}

.App main{
  flex: 1 1 auto;
  width: 100%;
  max-width: 1170px;
  margin: auto;
  padding: 1rem;
  box-sizing: border-box;
}

.App footer{
  text-align: right;
  font-size: 0.8rem;
  padding: .25rem;
}